import AppLayout from 'layout';
import React, { useEffect, useState, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, Outlet, useLocation } from 'react-router-dom';
// actions
import { getAuthUserAction as getAuthUser } from 'redux/actions/auth';
import { getConfigAction as getConfig } from 'redux/actions/config';
// components
import Loading from 'components/Loading';
import Login from 'routes/auth/Login';
import Overview from 'routes/overview';
// import Users from 'routes/users/UserList';
import RoleList from 'routes/role/RoleList';
import RoleDetail from 'routes/role/RoleDetail';
import CountryList from 'routes/country/CountryList';
import CountryDetail from 'routes/country/CountryDetail';
import StateList from 'routes/country/StateList';
import CurrencyList from 'routes/currency/CurrencyList';
import PlatformList from 'routes/platform/PlatformList';
import ModuleList from 'routes/module/ModuleList';
import PlanList from 'routes/plan/PlanList';
import ServiceList from 'routes/service/ServiceList';
import ServiceDetail from 'routes/service/ServiceDetail';
import PlatformCreateForm from 'routes/platform/PlatformCreateForm';
import PlatformDetail from 'routes/platform/PlatformDetail';
import ServiceCreateForm from 'routes/service/ServiceCreateForm';
import BillingConfig from 'routes/config/Billing';
import SubscriptionList from 'routes/subscription/SubscriptionList';
import EventList from 'routes/event/EventList';
import EmailTemplateList from 'routes/config/emailTemplate/EmailTemplateList';
import EmailTemplateDetail from 'routes/config/emailTemplate/EmailTemplateDetail';
import CustomerList from 'routes/customer/CustomerList';
import CustomerDetail from 'routes/customer/CustomerDetail';
import UserList from 'routes/users/UserList';
import UserDetail from 'routes/users/UserDetail';
import AppList from 'routes/app/AppList';
import AppDetail from 'routes/app/AppDetail';
import AppCreateForm from 'routes/app/AppCreateForm';

const ProtectedRoute = ({ children }) => {
	const location = useLocation();
	const authUser = useSelector((state) => state.auth.authUser);

	if (authUser) return children;

	return <Navigate to="/login" state={{ from: location }} replace />;
};

const AppRoutes = () => {
	const [loading, setLoading] = useState(true);

	const dispatch = useDispatch();

	useEffect(() => {
		async function getData() {
			try {
				setLoading(true);
				await dispatch(getAuthUser());
				await dispatch(getConfig());
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		}

		getData();
	}, []);

	if (loading) return <Loading />;

	return (
		<Routes>
			<Route exact path="/login" element={<Login />} />

			<Route
				element={
					<ProtectedRoute>
						<AppLayout>
							<Outlet />
						</AppLayout>
					</ProtectedRoute>
				}
			>
				<Route exact path="/" element={<Navigate to="/overview" />} />
				<Route exact path="/overview" element={<Overview />} />
				<Route exact path="/users" element={<UserList />} />
				<Route exact path="/users/:id" element={<UserDetail />} />
				<Route exact path="/roles" element={<RoleList />} />
				<Route exact path="/roles/:id" element={<RoleDetail />} />
				<Route exact path="/countries" element={<CountryList />} />
				<Route exact path="/countries/:id" element={<CountryDetail />} />
				<Route exact path="/countries/:id/states" element={<StateList />} />
				<Route exact path="/currencies" element={<CurrencyList />} />
				<Route exact path="/platforms" element={<PlatformList />} />
				<Route exact path="/platforms/:id" element={<PlatformDetail />} />
				<Route exact path="/platforms/create" element={<PlatformCreateForm />} />
				<Route exact path="/modules" element={<ModuleList />} />
				<Route exact path="/plans" element={<PlanList />} />
				<Route exact path="/services" element={<ServiceList />} />
				<Route exact path="/services/:id" element={<ServiceDetail />} />
				<Route exact path="/services/create" element={<ServiceCreateForm />} />
				<Route exact path="/customers" element={<CustomerList />} />
				<Route exact path="/customers/:id" element={<CustomerDetail />} />
				<Route exact path="/subscriptions" element={<SubscriptionList />} />
				<Route exact path="/notification-events" element={<EventList />} />
				<Route exact path="/config/billing" element={<BillingConfig />} />
				<Route exact path="/config/email-templates" element={<EmailTemplateList />} />
				<Route exact path="/config/email-templates/:id" element={<EmailTemplateDetail />} />
				<Route exact path="/apps" element={<AppList />} />
				<Route exact path="/apps/:id" element={<AppDetail />} />
				<Route exact path="/apps/create" element={<AppCreateForm />} />
			</Route>
		</Routes>
	);
};

export default AppRoutes;
