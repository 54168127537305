import React, { useEffect, useState } from 'react';

const Overview = () => {
    return (
        <div>
            Main content
        </div>
    )
}

export default Overview;