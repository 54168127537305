import { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
// images
import logo from 'assets/images/logo.svg';
import logoIcon from 'assets/images/logo_icon.svg';
// icons
import {
	ChartSquareBarIcon,
	CurrencyDollarIcon,
	GlobeIcon,
	UserGroupIcon,
	CloudIcon,
	ServerIcon,
	CalendarIcon,
	ShareIcon,
	UsersIcon,
	CogIcon,
	NewspaperIcon,
	MailIcon,
	ColorSwatchIcon,
	ArrowLeftIcon,
	ArrowRightIcon
} from '@heroicons/react/outline';

const { Sider } = Layout;

const menu = [
	{
		title: 'Overview',
		key: 'overview',
		icon: <ChartSquareBarIcon width={24} height={24} />,
		path: '/overview',
	},
	{
		title: 'Users',
		key: 'user_management',
		icon: <UserGroupIcon width={24} height={24} />,
		// path: '/users',
		childrens: [
			{
				title: 'Roles',
				key: 'roles',
				path: '/roles',
			},
			{
				title: 'Users',
				key: 'users',
				path: '/users',
			},
		],
	},
	{
		title: 'Subscriptions',
		key: 'subscriptions',
		icon: <NewspaperIcon width={24} height={24} />,
		path: '/subscriptions',
	},
	{
		title: 'Customers',
		key: 'customers',
		icon: <UsersIcon width={24} height={24} />,
		path: '/customers',
	},
	{
		title: 'Countries',
		key: 'countries',
		icon: <GlobeIcon width={24} height={24} />,
		path: '/countries',
	},
	{
		title: 'Currencies',
		key: 'currencies',
		icon: <CurrencyDollarIcon width={24} height={24} />,
		path: '/currencies',
	},
	{
		title: 'Platforms',
		key: 'platforms',
		icon: <CloudIcon width={24} height={24} />,
		path: '/platforms',
	},
	{
		title: 'Modules',
		key: 'modules',
		icon: <ServerIcon width={24} height={24} />,
		path: '/modules',
	},
	{
		title: 'Plans',
		key: 'plans',
		icon: <CalendarIcon width={24} height={24} />,
		path: '/plans',
	},
	{
		title: 'Services',
		key: 'services',
		icon: <ShareIcon width={24} height={24} />,
		path: '/services',
	},
	{
		title: 'Apps',
		key: 'apps',
		icon: <ColorSwatchIcon width={24} height={24} />,
		path: '/apps',
	},
	{
		title: 'Events',
		key: 'notification-events',
		icon: <MailIcon width={24} height={24} />,
		path: '/notification-events',
	},
	{
		title: 'Config',
		key: 'config',
		icon: <CogIcon width={24} height={24} />,
		childrens: [
			{
				title: 'Email Templates',
				key: 'email-templates',
				path: '/config/email-templates',
			},
			{
				title: 'Billing',
				key: 'billing',
				path: '/config/billing',
			},
		],
	},
];

const AppSidebar = (props) => {
	const { isCollapsed, toggleCollapse } = props;

	const [selectedKeys, setSelectedKeys] = useState([]);
	const [openKeys, setOpenKeys] = useState([]);

	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		let childIndex = -1;
		const selectedMenuItem = menu.find((item) => {
			if (item.childrens) {
				let tmp = item.childrens.findIndex((child) => location.pathname.startsWith(child.path));

				if (tmp >= 0) {
					childIndex = tmp;
					return true;
				}
				return false;
			} else {
				return location.pathname.startsWith(item.path);
			}
		});

		if (selectedMenuItem) {
			if (selectedMenuItem.childrens) {
				setSelectedKeys([selectedMenuItem.key, selectedMenuItem.childrens[childIndex].key]);
				setOpenKeys([selectedMenuItem.key]);
			} else {
				setSelectedKeys([selectedMenuItem.key]);
			}
		}
	}, [location.pathname]);

	useEffect(() => {
        if (isCollapsed) setOpenKeys([]);
    }, [isCollapsed]);

	const onToggleSubMenu = (key) => {
		if (openKeys.includes(key)) {
			setOpenKeys([]);
		} else {
			setOpenKeys([key]);
		}
	};

	return (
		<Sider className="app-sidebar" theme="light" collapsed={isCollapsed}>
			{/* <img src={logo} className="logo" /> */}
			{isCollapsed ? (
                <img src={logoIcon} className="logo-collapsed mb-32 ml-24 mr-24" />
            ) : (
                <img src={logo} className="logo mb-32 ml-24 mr-24" />
            )}
			<Menu mode="inline" className="sidebar-menu" selectedKeys={selectedKeys} openKeys={openKeys}>
				<Menu.Item
                    key={'collapse'}
                    icon={
                        isCollapsed ? (
                            <ArrowRightIcon width={24} height={24} />
                        ) : (
                            <ArrowLeftIcon width={24} height={24} />
                        )
                    }
                    onClick={toggleCollapse}
                    className="menu-item--collapse"
                >
                    {isCollapsed ? 'Expand Menu' : 'Collapse Menu'}
                </Menu.Item>
				{menu.map((item) => {
					if (item.childrens && item.childrens.length) {
						return (
							<Menu.SubMenu
								key={item.key}
								icon={item.icon}
								title={item.title}
								onTitleClick={() => onToggleSubMenu(item.key)}
							>
								{item.childrens.map((child) => (
									<Menu.Item key={child.key} icon={child.icon} onClick={() => navigate(child.path)}>
										{child.title}
									</Menu.Item>
								))}
							</Menu.SubMenu>
						);
					}
					return (
						<Menu.Item key={item.key} icon={item.icon} onClick={() => navigate(item.path)}>
							{item.title}
						</Menu.Item>
					);
				})}
			</Menu>
		</Sider>
	);
};

export default AppSidebar;
