export const MODULES_TYPES = [
	{ display: 'Payment', value: '1' },
	{ display: 'Shipment', value: '2' },
	{ display: 'Notification', value: '3' },
	{ display: 'Accountant', value: '4' },
];

export const DEFAULT_QUERY = {
	page: 1,
	per_page: 10,
};

export const EVENT_TYPES = [
	{ display: 'Order', value: 'order' },
	{ display: 'Shipment', value: 'shipment' },
	{ display: 'Product', value: 'product' },
];
