import { Checkbox, Input, Form } from "antd";
import BaseSelect from "components/Elements/BaseSelect";

export function generateFormElement(field) {
	// if (field.type === 'hidden') return null;
	
	let element = <Input />;
	if (field.type === 'textarea') element = <Input.TextArea />;
	else if (field.type === 'select') element = <BaseSelect options={field.options} optionLabel='label' optionValue='value' defaultText="Select one" />
	else if (field.type === 'checkbox') element = <Checkbox.Group options={field.options} />;

	return (
		<Form.Item name={field.name} label={field.label} extra={field.description} rules={[{ required: field.is_required }]}>
			{element}
		</Form.Item>
	)
}
