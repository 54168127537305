import { toast } from 'react-toast';
import api from 'utils/api';

export const getUsers = (query) => {
	return new Promise((resolve, reject) => {
		return api
			.get('/admin/users', { params: query })
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const createUser = (data) => {
	return new Promise((resolve, reject) => {
		return api
			.post('/admin/users', data)
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				toast.error(err.response.data.message);
				reject(err);
			});
	});
};

export const getUser = (id) => {
	return new Promise((resolve, reject) => {
		return api
			.get(`/admin/users/${id}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const updateUser = (id, data) => {
	return new Promise((resolve, reject) => {
		return api
			.put(`/admin/users/${id}`, data)
			.then((response) => {
				toast.success('Success');
				resolve(response.data);
			})
			.catch((err) => {
				toast.error(err.response.data.message);
				reject(err);
			});
	});
};

export const deleteUser = (id) => {
	return new Promise((resolve, reject) => {
		return api
			.delete(`/admin/users/${id}`)
			.then((response) => {
				toast.success('Success');
				resolve(response.data);
			})
			.catch((err) => {
				toast.error(err.response.data.message);
				reject(err);
			});
	});
};

export const assignPermissions = (id, data) => {
	return new Promise((resolve, reject) => {
		return api
			.put(`/admin/users/${id}/permissions`, data)
			.then((response) => {
				toast.success('Success');
				resolve(response.data);
			})
			.catch((err) => {
				toast.error(err.response.data.message);
				reject(err);
			});
	});
};

export const assignUsersToParent = (data) => {
	return new Promise((resolve, reject) => {
		return api
			.post(`/admin/users/assign`, data)
			.then((response) => {
				toast.success('Success');
				resolve(response.data);
			})
			.catch((err) => {
				toast.error(err.response.data.message);
				reject(err);
			});
	});
};

export const unassignUsersFromParent = (data) => {
	return new Promise((resolve, reject) => {
		return api
			.post(`/admin/users/unassign`, data)
			.then((response) => {
				toast.success('Success');
				resolve(response.data);
			})
			.catch((err) => {
				toast.error(err.response.data.message);
				reject(err);
			});
	});
};

export const getUserStores = (id, query) => {
	return new Promise((resolve, reject) => {
		return api
			.get(`/admin/users/${id}/stores`, { params: query })
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getUserStore = (userId, id, query) => {
	return new Promise((resolve, reject) => {
		return api
			.get(`/admin/users/${userId}/stores/${id}`, { params: query })
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const fetchUserAccessToken = (userId) => {
	return new Promise((resolve, reject) => {
		return api
			.post(`/admin/users/${userId}/access-token`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
